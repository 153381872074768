<template>
  <div class="background">
    <div class="z_loading" v-if="loading">
      <img src="@/assets/loading.gif" alt="" />
    </div>
    <div class="typeArea content">
      <div class="z_index">
        <div class="left">
          <div class="content-top">
            <div class="top-title">
              {{ reportingGuidelinesDetails.PROJECT_NAME }}
            </div>
            <div class="top-tag">
              <div class="tag-left">
                <div class="left">
                  <!-- <div
                class="tag-price jinxing"
                v-if="reportingGuidelinesDetails.PROJECT_DATE_END>Date.parse(new Date())/1000"
              >
                进行中
              </div>
              <div
                class="tag-price jiezhi"
                v-else
              >
                已截止
              </div> -->
                  <div class="basicSituation-item">
                    <span class="wrongline" v-if="reportingGuidelinesDetails.PROJECT_GOVERNMENT === false">——</span>
                    <div class="item-details details-color z_fabujigou"
                      v-if="reportingGuidelinesDetails.PROJECT_GOVERNMENT !== false">
                      {{ reportingGuidelinesDetails.PROJECT_GOVERNMENT }}
                    </div>
                    <div v-if="
                      reportingGuidelinesDetails.PROJECT_GOVERNMENT !== false &&
                      reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID !== '' &&
                      reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID !== null
                    " class="to-subscribe-to" :class="{ alerdSubscribe: isSubscribe }"
                      @click="reportingGuidelinesDetailsSubscribe">
                      <div class="dingyue">订阅</div>
                      <div class="quxiao">取消</div>
                    </div>
                  </div>
                  <!--                <div class="z_xian"></div>-->
                  <!-- <div class="tag">
                  <div
                    class="tag-item"
                    v-for="(ite,index) in reportingGuidelinesDetails.tagsArray"
                    :key="index"
                    @mouseenter="tagmouseenter($event)"
                    @mouseleave="tagmouseleave($event)"
                  >
                    <div class="text">
                      {{ite}}
                    </div>
                    <div
                      class="add"
                      @click="tagtoSubscribe($event)"
                    >
                      +
                    </div>
                  </div>
                </div> -->
                  <div class="create-time">
                    <!--                {{ reportingGuidelinesDetails.showTimeText }}-->
                    <div class="item-title">发布时间：</div>
                    <div class="item-details details-color price-margin" style="color:#5E068C;">
                      {{ reportingGuidelinesDetails.showTimeText }}
                    </div>
                  </div>
                  <!--                <div class="z_xian"></div>-->
                  <!-- <div
                  class="tag-price"
                  v-if="reportingGuidelinesDetails.PROJECT_FUNDS!==null"
                >
                  {{reportingGuidelinesDetails.PROJECT_FUNDS}}万元
                </div> -->
                </div>
                <div class="right">
                  <div class="share-item">
                    <div class="share-image">
                      <img src="../../../../assets/image/科研项目_slices/详情页/微信.png" alt="" />
                    </div>
                    <div class="share-text">告诉小伙伴</div>
                    <!-- <div
                    class="wxer-code"
                    id="wxer-code"
                    ref="paycode"
                    :style="{transform: 'scale('+$store.state.suofangbi+')'}"
                  > -->
                    <!-- <img
                      src="../../../../assets/image/科研项目_slices/详情页/科研人个人二维码@2x.png"
                      alt=""
                    > -->
                    <!-- </div> -->
                    <div class="wxer-code">
                      <vue-qr ref="Qrcode" :logoSrc="imgUrl" :text="codeUrl" :size="200" :margin="8" :logoScale="0.23"
                        qid="testQrId"></vue-qr>
                      <!-- <a
                      :href="exportLink"
                      @click="downloadImg"
                      :download="downloadFilename"
                    >下载二维码</a> -->
                    </div>
                  </div>
                  <div class="share-item" @click="attentionProject">
                    <div class="share-image">
                      <img v-if="isAttention" src="../../../../assets/image/科研项目_slices/详情页/五角星选中.png" alt="" />
                      <img v-else src="../../../../assets/image/科研项目_slices/详情页/五角星.png" alt="" />
                    </div>
                    <div class="share-text">收藏</div>
                  </div>
                </div>
              </div>
              <div class="tag-right">
                <div class="left">
                  <div class="basicSituation-item item">
                    <div class="item-title">资助强度：</div>
                    <div class="item-details details-color price-margin" style="color:#5E068C;">
                      {{ reportingGuidelinesDetails.PROJECT_FUNDS }}
                    </div>
                    <div class="item-details" v-if="reportingGuidelinesDetails.PROJECT_FUNDS !== null"
                      style="color:#5E068C;">
                      万元
                    </div>
                    <span class="wrongline" v-if="reportingGuidelinesDetails.PROJECT_FUNDS === null"
                      style="color:#5E068C;">——</span>
                  </div>
                  <div class="basicSituation-item item">
                    <div class="item-title">申报时间：</div>
                    <div class="item-details details-color price-margin" style="color:#5E068C;letter-spacing: -0.5px;">
                      {{ reportingGuidelinesDetails.dateformatestart }}
                      -
                      {{ reportingGuidelinesDetails.dateformateend }}
                    </div>
                  </div>
                  <div class="basicSituation-item item" v-if="reportingGuidelinesDetails.remainTime >= 0">
                    <div class="item-title">剩余：</div>
                    <div class="item-details details-color price-margin" style="color:#5E068C;">
                      {{ reportingGuidelinesDetails.remainTime }}天
                    </div>
                  </div>
                </div>
                <div class="right">
                  <img src="@/assets/image/svg/指南-申报中.svg" alt=""
                    v-if="new Date(reportingGuidelinesDetails.dateformateend) > new Date()">
                  <img src="@/assets/image/svg/指南-已截至.svg" alt="" v-else>
                </div>
              </div>
            </div>
          </div>
          <div class="content-middle" :style="{height: !isLogin ? '550px':'auto' }">
            <!-- <div class="middle-title">基本情况</div>
          <div class="basicSituation">
            <div class="basicSituation-item">
              <div class="item-title">文 号</div>

              <span
                class="wrongline"
                v-if="reportingGuidelinesDetails.PROJECT_DOC_ID === null"
                >——</span
              >
              <div v-else class="item-details">
                {{ reportingGuidelinesDetails.PROJECT_DOC_ID }}
              </div>
            </div>
            <div class="basicSituation-item">
              <div class="item-title">课题方向</div>
              <div class="item-details">
                {{ reportingGuidelinesDetails.PROJECT_TYPE }}
              </div>
            </div>

            <div class="basicSituation-item">
              <div class="item-title">资助范围</div>
              <div class="item-details">
                {{ reportingGuidelinesDetails.area_name }}
                <span
                  class="wrongline"
                  v-if="reportingGuidelinesDetails.area_name === ''"
                  >——</span
                >
              </div>
            </div>
          </div> -->
            <div class="project-paper">
              <!-- <div class="paper-title">公告原文</div> -->
              <div class="paper-content" style="height: auto;border: 0;padding: 0;white-space: break-spaces;"
                v-html="wztext"></div>
              <div>凡文中提到附件的，请点击文末 <span style="font-weight: bold;">“原文”</span> 到官方页面下载</div>
              <div style="font-size: 14px;color: #9A9A9A;display: flex;align-items: center;margin: 20px 0;"><span
                  style="margin-right: 4px;padding-bottom: 2px;">●</span> 本信息由科研人整理，相关信息请以官方文件为准
                <div style="height: 14px;width: 2px;background-color: rgb(247, 245, 245);margin: 0 6px;"></div>
                <span style="cursor: pointer;">
                  <a style="color: #5e068c;" href="/pc/content/declarationAndTerms">声明&条款</a>
                </span>
              </div>

              <div class="paper-link">
                <div class="original-link" @click="hiddencontentclick(reportingGuidelinesDetails)"
                  v-if="reportingGuidelinesDetails.PROJECT_URL !== null">
                  查看原文
                </div>

                <!-- <div
                v-else
                class="noriginal-link"
                @click="hiddencontentclick"
              >原文链接</div> -->
              </div>
            </div>
          </div>
          <div class="content-bottom" style="display:none;">
            <div class="bottom-header">相似项目</div>
            <div class="bottom-content">
              <table>
                <thead>
                  <tr>
                    <td>类型</td>
                    <td>状态</td>
                    <td>课题名称</td>
                    <td>发布单位</td>
                    <td>主要方向</td>
                    <td>项目范围</td>
                    <td>资助力度<br />(万元)</td>
                    <td>发布时间</td>
                    <td>截止时间</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item2 in similarProjects.slice(0, 10)" :key="item2.id">
                    <td>
                      <div class="zhoabiao">招标</div>
                    </td>
                    <td v-if="
                      Date.parse(new Date()) <
                      new Date(item2.PROJECT_DATE_END).getTime()
                    ">
                      招标中
                    </td>
                    <td v-else class="td-jiezhi">已截止</td>
                    <td @click="toReportingGuideDetail(item2)">
                      {{ item2.PROJECT_NAME }}
                    </td>
                    <td>
                      <span v-if="item2.PROJECT_GOVERNMENT !== false">{{
                        item2.PROJECT_GOVERNMENT
                        }}</span>
                      <span class="wrongline" v-if="item2.PROJECT_GOVERNMENT === false">——</span>
                    </td>
                    <td>{{ item2.PROJECT_CONTENT_KEYWORDS }}</td>
                    <td>
                      <span class="wrongline" v-if="item2.PROJECT_GOVERNMENT_PRO === ''">——</span>
                      <span v-else>{{ item2.PROJECT_GOVERNMENT_PRO }}</span>
                    </td>
                    <td>
                      {{ item2.PROJECT_FUNDS }}
                      <span class="wrongline" v-if="item2.PROJECT_FUNDS === null">——</span>
                    </td>
                    <td>{{ item2.PROJECT_DATE.split(" ")[0] }}</td>
                    <td>{{ item2.PROJECT_DATE_END.split(" ")[0] }}</td>
                  </tr>
                  <!-- <tr
                  v-for="item3 in similarProjectsZCZB"
                  :key="item3.id"
                >
                  <td>
                    <div class="zhoabiao">招标</div>
                  </td>
                  <td v-if="item3.TENDER_END_TIME >=item3.TENDER_START_TIME">招标中</td>
                  <td
                    v-else
                    class="td-jiezhi"
                  >已截止</td>
                  <td
                    :title="item3.TENDER_NAME"
                    @click="toNewTenderDetail(item3)"
                  >{{item3.TENDER_NAME}}</td>
                  <td :title="item3.TENDER_ORGANIZATION"> <span v-if="item3.TENDER_ORGANIZATION==null">——</span> {{item3.TENDER_ORGANIZATION}}</td>
                  <td :title="item3.TENDER_KEYWORDS">{{item3.TENDER_KEYWORDS}}</td>
                  <td> <span v-if="item3.TENDER_PROVINCE==null">——</span> {{item3.TENDER_PROVINCE}}</td>
                  <td :title="item3.TENDER_MONEY"> <span v-if="item3.TENDER_MONEY==null">——</span>
                    {{item3.TENDER_MONEY}}
                  </td>
                  <td>{{item3.dateformatestart}}</td>
                  <td>{{item3.dateformateend}}</td>
                </tr> -->
                </tbody>
              </table>
            </div>
          </div>
          <div class="login-box" v-if="!isLogin">
            <div class="login-jianbian"></div>
            <div class="login-bottom">
              <div class="login-titles">
                <div>马上登录 查看全文</div>
                <div>畅览4万条指南</div>
              </div>
              <div class="form login-content">
                <div class="username" :class="{ errName: !isUserNameRight }">
                  <img src="../../../../assets/image/登录页/姓名.png" alt="">
                  <input type="text" v-model="usermobile" minlength="11" maxlength="11">
                </div>
                <div class="z_yzm">
                  <div class="password" :class="{ errPassWord: !isUserPassWordRight1 }">
                    <img src="../../../../assets/image/登录页/密码1.png" alt="">
                    <input id="code" type="text" v-model="usercode" oninput="value=value.replace(/[^\d]/g,'')"
                      maxlength="6">
                  </div>
                  <el-button class="anniu" v-show="show" @click="tosendthecode">获取验证码</el-button>
                  <el-button class="anniu" v-show="!show">{{ count }} s</el-button>
                </div>
                <div class="login-button" @click="clicktologin1()">
                  登录
                </div>
                <div class="to-registed">
                                  还没有账号？点击这里<span @click="toRegistered">注册</span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="entry-item" v-if="showSendStatus == 1">
            <div class="float-bg"></div>
            <div class="wx-pub-top">
              <div class="wx-pub-top-left">
                <div class="wx-pub-top-left-title">关注公众号 领{{ day1 }}天VIP</div>
                <div class="wx-pub-top-left-desc">关注后，在公众号后台回<br>复“领VIP”，获取口令后填<br>写至下方输入框即可获取</div>
              </div>
              <div class="wx-pub-top-right">
                <img src="../../../../assets/image/wx-pub-qr.png" alt="">
              </div>
            </div>
            <div class="wx-pub-bottom">
              <div class="input-wrapper">
                <input v-model="sub_code" type="text" placeholder="输入口令">
              </div>
              <div class="entry-btn-wx" @click="lingqu">确认</div>
            </div>
          </div>
          <div class="entry-item1" v-if="showSendStatus == 2">
            <div class="float-bg"></div>
            <div class="wx-pub-top">
              <div class="wx-pub-top-left">
                <div class="wx-pub-top-left-title">订阅课题 送{{ day2 }}天VIP</div>
                <div class="wx-pub-top-left-desc">将最新的项目指南推送到您<br>的邮箱；初次订阅送{{ day2 }}天VIP</div>
                <div class="btn-sub">
                  <div class="btn" @click="toMySub">立即订阅</div>
                </div>
              </div>
              <div class="wx-pub-top-right">
                <img src="../../../../assets/image/sub-icon-tip.svg" alt="">
              </div>
            </div>
          </div>
          <div class="z_xgkt">
            <div class="title">相关课题</div>
            <ul>
              <li v-for="item2 in similarProjects.slice(0, 5)" :key="item2.id" @click="toReportingGuideDetail(item2)">
                <h1>
                  {{ item2.PROJECT_NAME }}
                </h1>
                <h2>{{ item2.PROJECT_DATE_END.split(" ")[0] }}</h2>
              </li>
            </ul>
          </div>

          <div class="z_ewmxcx" v-if="ewmxcx">
            <div class="li">
              <img src="../../../../assets/image/12341.jpg" alt="">
              <h1>微信小程序</h1>
              <h2>随时随地查看最新项目</h2>
            </div>
            <div class="li">
              <img src="../../../../assets/image/关注公众号1.jpg" alt="">
              <h1>微信公众号</h1>
              <h2>每日推送最新课题信息</h2>
            </div>
            <div class="close" @click="z_close">+</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog width="420px" style="background-color: transparent;" :visible.sync="showSendVipTip" :show-close="false">
      <!-- 自定义展示内容 -->
			<div class="contents bg-send">
				<img @click="onTipBoxClose()" class="close-tr" src="https://keyanpro.com/image/close.svg" />
				<img class="send-res1" src="https://keyanpro.com/image/send-res1.png" />
				<div class="send-title">领取成功</div>
				<div class="send-desc">送您<span>{{ day1 }}天VIP</span>，尽情体验科研人的强大功能吧</div>
				<div class="btn-send" @click="onTipBoxClose()">知 道 了</div>
			</div>
			<!-- 自定义关闭按钮 -->
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
  getReportingGuidelinesList,
  getReportingGuidelinesList1,
  organizationAdd,
  organizationJudge,
  subscribeadd,
  toAttentionProject,
  cancelAttentionProject,
  getsimilarprojects,
  cancelsubscribe,
  collectSelect
} from "../../../../api/details/reportingGuidelinesDetails";
import { tomoney, getHsjuser, getHsjhide, getSMScode } from '../../../../api/login/registered';
// eslint-disable-next-line no-unused-vars
import {
  formatDate,
  formatDate1,
  makePy,
  checkCh,
  mkRslt,
} from "../../../../util/tools";
import {
  Addhistoricalrecord,
  Addhistoricalrecordzc
} from "../../../../api/myRecentInformation";
import {
  showStatus,
  getSendDays,
  vipSend
} from "../../../../api/mySubscription";
// eslint-disable-next-line no-unused-vars
import QRCode from "qrcodejs2";
import vueQr from "vue-qr";
import {
  organizationDelete,
  organizationllist,
} from "../../../../api/mySubscription";
import { getProuser, toLogined1 } from "@/api/login/login";
export default {
  data() {
    return {
      // 登录用户id
      uid: null,
      // 关注输入口令，领取成功弹窗提示
      showSendVipTip: false,
      // 输入框中的口令码
      sub_code: null,
      // 关注领取天数
      day1: null,
      // 订阅领取天数
      day2: null,
      // 显示赠送活动1 显示口令领取； 2 显示订阅领取； 3 都不显示
      showSendStatus: 3,
      // 是否记住密码
      radioischecked: true,
      // 倒计时实现
      show: true,
      count: '',
      tmr: null,
      // 用户手机号
      usermobile: '',
      // 用户验证码
      usercode: '',
      // 姓名
      userName: '',
      // 密码
      passWord: '',
      // 验证码
      passWord1: '',
      // 错误信息
      // 用户名
      nameErrMessage: '',
      // 密码
      passwordErrMessage: '',

      // 账号是否验证通过
      isUserNameRight: true,
      // 密码是否验证通过
      isUserPassWordRight: true,
      // 验证码是否正确
      isUserPassWordRight1: true,
      isLogin: false,
      codeUrl: "", // 二维码地址或内容
      imgUrl: "", // 二维码中间的logo图
      exportLink: "",
      downloadFilename: "", // 下载的图片文件名

      // 申报报指南详情数据
      reportingGuidelinesDetails: "",
      // 相似项目列表
      // 申报指南
      similarProjects: [],
      // 政采招标
      similarProjectsZCZB: [],
      // 是否订阅
      isSubscribe: false,
      // 是否关注项目
      isAttention: false,
      // 是否隐藏原文
      ishiddencontent: true,
      address: "https://www.baidu.com",
      windowWidth: document.documentElement.clientWidth,
      ewmxcx: false,
      loading: false,
      wztext: "",
      isColle: ''
    };
  },
  created() {
    // if (window.name === '') {
    //   this.ewmxcx = true
    // } else {
    //   this.ewmxcx = false
    // }
    // 获取初始化数据
    this.getData();
    this.goTop();
    // this.attentionProject();
  },
  // watch: {
  //   windowWidth (val, oldval) {
  //     // console.log(val)
  //     val = val.replace(/([1-9]\d*\.?\d*)|(0\.\d*[1-9])px/, '$1$2')

  //     const beishu = Number(val) / 100
  //     console.log(beishu)
  //     this.$refs.paycode.style.transform = `scale(${beishu})`
  //   }
  // },
  components: {
    vueQr,
  },
  mounted() {
    getSendDays({ type: 2 }).then(res => this.day1 = res.vip_day);
    getSendDays({ type: 3 }).then(res => this.day2 = res.vip_day);
    this.qrcode();
    const userM = localStorage.getItem('usermessage')
    if (!userM) {
      this.isLogin = false
    } else {
      const uid = JSON.parse(userM).id;
      this.uid = uid;
      showStatus({ user_id: uid }).then(res => this.showSendStatus = res);
      this.isLogin = true
    }
  },
  // inject: ['reload'],
  methods: {
    toRegistered() {
      this.$router.push({
        path: '/pc/loginroot/registered'
      })
    },
    // 跳转到我的订阅页面
    toMySub() {
      this.$router.push({
        path: "/pc/content/mySubscription"
      })
    },
    // 当关闭领取弹窗时候
    onTipBoxClose() {
      window.location.reload();
    },
    // 领取 确认
    lingqu() {
      if (!this.sub_code) {
        this.$message.error("请填写口令");
        return;
      }
      vipSend({user_id: this.uid, send_code: this.sub_code}).then(res=> {
        if(res.is_send_vip) {
          this.showSendVipTip = true;
          this.showSendStatus = 3;
        }
      })
    },
    // 获取短信验证码
    tosendthecode() {
      const that = this

      if (this.$data.usermobile === '') {
        this.$message.error('请填写手机号')
      } else {
        getSMScode(that.$data.usermobile).then(res => {
          if (res.indexOf('手机号码格式错误') > -1) {
            that.$message({
              message: '手机号码格式错误',
              type: 'error'
            })
            return
          }
          that.$message({
            message: '验证码发送成功',
            type: 'success'
          })
          const TIME_COUNT = 60
          if (!this.tmr) {
            this.count = TIME_COUNT
            this.show = false
            this.tmr = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.tmr)
                this.tmr = null
              }
            }, 1000)
          }
          console.log('短信验证码发送成功', res)
        }, err => {
          that.$message.error('验证码发送失败')
          console.log('短信验证码发送失败', err)
        })
      }
    },
    // 验证码点击登录
    clicktologin1() {
      const that = this
      if (that.$data.usermobile === '' || that.$data.usercode === '') {
        that.$message({
          message: '请填写手机号和验证码',
          type: 'warning',
        })
      } else {
        if (that.$data.nameErrMessage === '' && that.$data.passwordErrMessage === '') {
          const params = {
            mobile: that.$data.usermobile,
            code: that.$data.usercode
          }
          toLogined1(params).then(res => {
            console.log('登录返回res', res)
            if (res.code === 1) {
              that.$message({
                message: res.msg,
                type: 'success'
              })
              if (that.$data.radioischecked) {
                localStorage.setItem('useraccount', that.$data.userName)
                localStorage.setItem('userpassWord', that.$data.passWord)
              } else {
                localStorage.removeItem('useraccount', that.$data.userName)
                localStorage.removeItem('userpassWord', that.$data.passWord)
              }
              const usermessage = JSON.stringify(res.data.data)
              localStorage.setItem('usermessage', usermessage)
              localStorage.setItem('setLoginStatus', true)
              that.$store.commit('setLoginStatus', true)
              that.$store.commit('setUserid', res.data.data.user_id)
              getProuser({ user_id: res.data.data.user_id }).then(res => {
                console.log('用户是否为pro会员', res)
                // eslint-disable-next-line eqeqeq
                that.$store.commit('setvip_time_new', res.data.vip_time_new)

                if (res.data.level == 3) {
                  localStorage.setItem('ispro', true)
                  that.$store.commit('setispro', true)
                } else {
                  localStorage.setItem('ispro', false)
                  that.$store.commit('setispro', false)
                }
                setTimeout(() => {
                  localStorage.setItem('navActive', 1)
                  this.$store.commit('setnavActive', 1)
                  window.location.reload()
                }, 1000)
              }, err => {
                console.log('获取会员信息异常', err)
              })
            } else {
              that.$message({
                message: res.data.data,
                type: 'warning'
              })
            }
          }, err => {
            console.log('登录异常', err)
          })
        }
      }
    },
    z_close() {
      this.ewmxcx = false
      const params2 = {
        user_id: this.$store.state.userid,
      }
      getHsjhide(params2).then(
        (res) => {
          if (res.data.show == 1) {
            this.ewmxcx = true
          } else if (res.data.show == 0) {
            this.ewmxcx = false
          }
        },
        (err) => {

        }
      );
    },
    // 回调函数
    callback(dataUrl, id) {
      this.imgUrl = dataUrl;
    },
    // 下载二维码图片
    downloadImg() {
      const Qrcode = this.$refs.Qrcode;
      this.exportLink = Qrcode.$el.currentSrc;
      console.log(Qrcode, this.exportLink, "test");
      this.downloadFilename = "二维码";
    },

    // 格式化日期格式
    formateDateList(arr) {
      arr.forEach((element) => {
        if (element.PROJECT_DATE) {
          element.dateformatestart = formatDate(parseInt(element.PROJECT_DATE))
            .trim()
            .replace(/-/g, "/");
          element.dateformateend = formatDate(
            parseInt(element.PROJECT_DATE_END)
          )
            .trim()
            .replace(/-/g, "/");
        }
        if (element.TENDER_START_TIME) {
          element.dateformatestart = formatDate(
            parseInt(element.TENDER_START_TIME)
          )
            .trim()
            .replace(/-/g, "/");
          if (element.TENDER_END_TIME === false) {
            element.dateformateend = formatDate(
              parseInt(Date.parse(new Date())) / 1000
            )
              .trim()
              .replace(/-/g, "/");
          } else {
            element.dateformateend = formatDate(
              parseInt(element.TENDER_END_TIME)
            )
              .trim()
              .replace(/-/g, "/");
          }
        } else if (element.PROJECT_DATE !== null) {
          // eslint-disable-next-line eqeqeq
          if (element.PROJECT_DATE == false) {
            element.dateformatestart = "——";
          } else {
            element.dateformatestart = formatDate(
              parseInt(element.PROJECT_DATE)
            )
              .trim()
              .replace(/-/g, "-");
          }
          // eslint-disable-next-line eqeqeq
          if (element.PROJECT_DATE_END == false) {
            element.dateformateend = "——";
          } else {
            element.dateformateend = formatDate(
              parseInt(element.PROJECT_DATE_END)
            )
              .trim()
              .replace(/-/g, "-");
          }
        }
      });
    },
    // 点击显示原文
    hiddencontentclick(item) {
      window.open(item.PROJECT_URL);
      window._czc.push(["_trackEvent", "点击", "点击课题申报原文链接"]);
    },
    // 数据获取
    getData() {
      const that = this;
      const params1 = {
        user_id: this.$store.state.userid,
      }
      getHsjuser(params1).then(
        (res) => {
          if (res.data.show == 1) {
            this.ewmxcx = true
          } else if (res.data.show == 0) {
            this.ewmxcx = false
          }
        },
        (err) => {

        }
      );
      const id = this.$route.params.id;
      // 获取课题申报列表
      getReportingGuidelinesList({ limit: 12, page: 1, id: id }).then(
        (res) => {
          console.log(res);
          // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
          that.formateDateList(res.data);
          res.data = that.processingReturnData(res.data);
          console.log("申报指南详情", res.data);
          that.$data.reportingGuidelinesDetails = res.data[0];
          that.isColle = res.data[0].IN_PROJECT_GOV_ID;
          // debugger;
          document.getElementsByName("keywords")[0].content =
            this.$data.reportingGuidelinesDetails.PROJECT_CONTENT_KEYWORDS;
          document.getElementsByTagName("title")[0].innerHTML =
            this.$data.reportingGuidelinesDetails.PROJECT_NAME;
          const axios = require("axios");
          const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
          const appurl = "https://data.keyanpro.com/keyanpro3/";
          formData.append("in_project_gov_id", that.isColle); //添加文件对象 ，data中设置的
          axios({
            url: appurl + "ProjectInfo",
            method: "post",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (res) {
              console.log(res);
              that.wztext = res.data.resultList[0].PROJECT_CONTENT
              that.wztext = that.isLogin ? that.wztext : (that.wztext.substring(0, 300) + "......")
              console.warn(that.wztext)
              // that.$data.ReportingGuidelinesList1 = res.data.resultList;
            })
            .catch(function (error) {
              console.log(error);
            });
          // this.wztext = this.$data.reportingGuidelinesDetails.PROJECT_CONTENT;
          // console.warn(this.wztext);
          collectSelect({ title_id: res.data[0].IN_PROJECT_GOV_ID, type: 1, user_id: this.$store.state.userid }).then(sc => {
            if (sc.data.test == "已订阅") {
              this.isAttention = true
            }
          })
          // 获取相似项目列表
          getsimilarprojects({
            id: res.data[0].IN_PROJECT_GOV_ID,
          }).then((res) => {
            console.log("相似项目返回", res);
            that.formateDateList(res.data);
            res.data = that.processingReturnData(res.data);
            this.$data.similarProjects = res.data.slice(0, 10);
          });
          this.getorganizationJudge();
        },
        (err) => {
          console.log(err);
        }
      );
      // getReportingGuidelinesList1({ limit: 12, page: 1, id: id }).then(
      //   (res) => {
      //     console.log(res);
      //     debugger
      //     this.wztext = this.$data.reportingGuidelinesDetails.PROJECT_CONTENT;
      //   }
      // )
    },
    getData1(dsf) {
      const that = this;
      const id = dsf;
      // 获取课题申报列表
      getReportingGuidelinesList({ limit: 12, page: 1, id: id }).then(
        (res) => {
          console.log(res);
          // 该方法会给res.data中添加dateformate字段用来存储格式化后的创建时间
          that.formateDateList(res.data);
          res.data = that.processingReturnData(res.data);
          console.log("申报指南详情", res.data);
          that.$data.reportingGuidelinesDetails = res.data[0];
          // 获取相似项目列表
          // getsimilarprojects({
          //   keywords: res.data[0].PROJECT_CONTENT_KEYWORDS,
          //   type: 1,
          //   organization_id: "",
          // }).then((res) => {
          //   console.log("相似项目返回", res);
          //   this.$data.similarProjects = res.data;
          // });
          // getsimilarprojects({
          //   keywords: res.data[0].PROJECT_CONTENT_KEYWORDS,
          //   type: 2,
          //   organization_id: "",
          // }).then((res) => {
          //   console.log("相似项目返回qweqQRCode", res);
          //   this.$data.similarProjectsZCZB = res.data;
          // });
          getsimilarprojects({
            id: res.data[0].IN_PROJECT_GOV_ID,
          }).then((res) => {
            console.log("相似项目返回", res);
            that.formateDateList(res.data);
            res.data = that.processingReturnData(res.data);
            this.$data.similarProjects = res.data.slice(0, 10);
          });

          this.getorganizationJudge();
        },
        (err) => {
          console.log(err);
        }
      );
    },
    // 获取当前机构是否订阅
    getorganizationJudge() {
      const that = this;
      const id = that.$store.state.userid;
      // that.loading = true
      let oid = "";
      // eslint-disable-next-line eqeqeq
      if (that.$data.reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID == null) {
        oid = "";
      } else {
        oid =
          that.$data.reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID.split(
            ";"
          )[0];
      }

      const params = {
        user_id: id,
        organization_id: oid,
      };
      organizationJudge(params).then(
        (res) => {
          if (res.msg === "该机构未订阅!") {
            that.$data.isSubscribe = false;
            that.$data.loading = false
          } else {
            that.$data.isSubscribe = true;
            that.$data.loading = false
          }
        },
        (err) => {
          console.log("失败", err);
        }
      );
    },
    // 时间转换成显示时间
    // getTimeToShow(pushTime) {
    //   const nowtime = Date.parse(new Date());
    //   const sendtime = parseInt(pushTime) * 1000;
    //   const time = nowtime - sendtime;
    //   if (time < 60000) {
    //     return "刚刚";
    //   } else if (time > 60000 && time < 3600000) {
    //     return Math.floor(time / 60000) + "分钟前";
    //   } else if (time > 3600000 && time < 86400000) {
    //     return Math.floor(time / 3600000) + "小时前";
    //   } else if (time > 86400000 && time < 604800000) {
    //     return Math.floor(time / 86400000) + "天前";
    //   } else {
    //     return formatDate1(parseInt(pushTime));
    //   }
    // },
    getTimeToShow(pushTime) {
      const nowtime = Date.parse(new Date());
      const sendtime = parseInt(pushTime) * 1000;
      const time = nowtime - sendtime;
      if (time < 60000) {
        return "刚刚";
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + "分钟前";
      } else {
        return formatDate(parseInt(pushTime));
      }
    },
    // 根据时间显示周几
    getWeek(pushTime) {
      const date = new Date(parseInt(pushTime * 1000));
      var week = date.getDay();
      console.log("week", week);
      if (week === 0) {
        return "星期日";
      } else if (week === 1) {
        return "星期一";
      } else if (week === 2) {
        return "星期二";
      } else if (week === 3) {
        return "星期三";
      } else if (week === 4) {
        return "星期四";
      } else if (week === 5) {
        return "星期五";
      } else if (week === 6) {
        return "星期六";
      }
    },
    // 计算剩余几天
    getremaintime(endTime) {
      if (endTime === false) {
        const time =
          parseInt(Date.parse(new Date())) - parseInt(Date.parse(new Date()));
        const a = `${Math.floor(parseInt(time) / 86400000 + 1)}`;
        console.log(a);
        return `${Math.floor(parseInt(time) / 86400000 + 1)}`;
      } else {
        const timenow = Date.parse(new Date());
        const timesend = Number(endTime) * 1000;
        const time = timesend - timenow;
        const a = `${Math.floor(parseInt(time) / 86400000)}`;
        console.log(a);
        return `${Math.floor(parseInt(time) / 86400000)}`;
      }
    },
    // 跳转到最新政采招标信息详情页
    toNewTenderDetail(item) {
      localStorage.setItem("navActive", 3);
      this.$store.commit("setnavActive", 3);
      const id = item.TENDER_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecordzc(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.TENDER_MD5;
      if (md === null) {
        md = "";
      }
      this.$router.push({
        name: "politicsInTheTenderDetails",
        params: {
          id: id,
          upadte: true,
        },
        query: {
          PROJECT_GOV_MD5: md,
        },
      });
      // this.$router.go(0)
      // this.getData()
      window._czc.push(["_trackEvent", "点击", "跳转到政采招标详情页"]);
    },
    // 公告原文数据分段
    datapiecewise(str) {
      const newstr = str
        .replace(/([一,二,三,四,五,六,七,八,九,十]+)、/g, "<br><br>$1、")
        .replace(/([0-9]+)\./g, "<br><br>$1.")
        .replace(/邮政编码：/g, "<br><br>邮政编码：")
        .replace(/联 系 人：/g, "<br><br>联 系 人：")
        .replace(/联系电话：/g, "<br><br>联系电话：")
        .replace(/电子邮箱：/g, "<br><br>电子邮箱：")
        .replace(/附件：/g, "<br><br>附件：")
        .replace(
          /([\u4e00-\u9fa5]+)(\s+)([\u4e00-\u9fa5]+)(\s+)(\d{4}年\d{1,2}月\d{1,2}日)(\s+)(附件\d*)(\s+)([\u4e00-\u9fa5]+)/g,
          "$1$2<br><br>$3$4<br>$5$6<br><br>$7$8<br>$9"
        )
        // .replace(/<br><br>1./g, '1.')
        .replace(
          /([一,二,三,四,五,六,七,八,九,十]+、)([\u4e00-\u9fa5]+)(\s+)([\u4e00-\u9fa5]+)/g,
          "$1$2<br><br>$3$4"
        );
      return newstr;
    },

    // 返回数据处理
    processingReturnData(arr) {
      const that = this;
      arr.forEach((elem) => {
        if (elem.PROJECT_CONTENT_KEYWORDS) {
          elem.tagsArray = elem.PROJECT_CONTENT_KEYWORDS.split(";");
        }
        if (elem.PROJECT_DATE) {
          elem.showTimeText = that.getTimeToShow(elem.PROJECT_DATE);
          elem.week = that.getWeek(elem.PROJECT_DATE);
          elem.remainTime = that.getremaintime(elem.PROJECT_DATE_END);
        }
        if (elem.PROJECT_CONTENT) {
          elem.PROJECT_CONTENT = that.datapiecewise(elem.PROJECT_CONTENT);
        }
      });
      return arr;
    },
    // 订阅
    reportingGuidelinesDetailsSubscribe() {
      const that = this;
      if (that.$data.reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID !== "") {
        const id = that.$store.state.userid;
        // const oid = that.$data.reportingGuidelinesDetails.PROJECT_GOVERNMENT.split(' ')[0]
        const oid = (
          that.$data.reportingGuidelinesDetails.PROJECT_GOVERNMENT_ID + ""
        ).split(";")[0];
        if (this.$data.isSubscribe === false) {
          this.$data.isSubscribe = true;
          const params = {
            user_id: id,
            organization_id: oid,
          };
          organizationAdd(params).then(
            (res) => {
              console.log("成功", res);
            },
            (err) => {
              console.log("失败", err);
            }
          );
        } else {
          const id = oid.trim();

          const userid = that.$store.state.userid;
          organizationllist({ user_id: userid }).then((res) => {
            console.log("res", res);
            const dingyuelist = res;
            dingyuelist.forEach((ele) => {
              // eslint-disable-next-line eqeqeq
              if (ele.organization_id == id) {
                organizationDelete({ id: ele.id }).then((res) => {
                  // eslint-disable-next-line eqeqeq
                  if (res == 1) {
                    that.$data.isSubscribe = false;
                  } else {
                  }
                });
              }
            });
          });
        }
        window._czc.push(["_trackEvent", "点击", "订阅申报指南机构"]);
      }
    },
    // 鼠标移入显示标签订阅按钮
    tagmouseenter(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.add(
        "show"
      );
    },
    // 鼠标移除隐藏订阅按钮
    tagmouseleave(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.remove(
        "show"
      );
    },
    // 点击加号订阅标签
    tagtoSubscribe(event) {
      const that = this;
      const id = that.$store.state.userid;
      const keywords = event.currentTarget.previousElementSibling.innerText;
      console.dir(keywords);
      const type = 1;
      const params = {
        user_id: id,
        keywords: keywords,
        type: type,
      };
      subscribeadd(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          that.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          that.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
      window._czc.push(["_trackEvent", "点击", "订阅标签"]);
    },
    // 跳转到最新课题申报信息详情页
    // 跳转到最新课题申报信息详情页
    toReportingGuideDetail(item) {
      localStorage.setItem("navActive", 2);
      this.$store.commit("setnavActive", 2);
      const id = item.IN_PROJECT_GOV_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecord(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_GOV_MD5;
      if (md === null) {
        md = "";
      }
      this.$router.push({
        name: "reportingGuidelinesDetails",
        params: {
          id: id,
          upadte: true,
        },
        query: {
          PROJECT_GOV_MD5: md,
        },
      });
      this.getData1(id);
      // window.location.reload()
      window._czc.push(["_trackEvent", "点击", "跳转到课题申报详情页"]);
    },
    // 关注项目
    attentionProject() {
      const id = this.isColle;
      const type = 1;
      const userid = this.$store.state.userid;
      const params = {
        title_id: id,
        type: type,
        user_id: userid,
      };
      if (this.$data.isAttention === false) {
        this.$data.isAttention = !this.$data.isAttention;
        toAttentionProject(params).then(
          (res) => {
            console.log("成功", res);
          },
          (err) => {
            console.log("失败", err);
          }
        );
      } else {
        this.$data.isAttention = !this.$data.isAttention;
        cancelAttentionProject(params).then(
          (res) => {
            console.log("成功", res);
          },
          (err) => {
            console.log("失败", err);
          }
        );
      }
      window._czc.push(["_trackEvent", "点击", "关注课题申报"]);
    },
    qrcode() {
      var scene = this.$store.state.userid;
      const address =
        this.$store.state.wxreportbaseurl +
        "&scene=" +
        scene +
        "&wid=" +
        this.$route.params.id;
      this.$data.codeUrl = address;
    },
    goTop() {
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 50;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrongline {
  color: #000 !important;
  font-size: 12px !important;
}

// 版心
.background {
  // background-color: #f1f2f6;
  overflow: hidden;
}

.typeArea {
  width: 1200px;
  margin: 0 auto;
}

.show {
  display: block !important;
}

.content {
  margin-top: 60px;

  .content-top {
    background-color: #fff;
    box-sizing: border-box;
    // padding: 40px;
    padding-bottom: 29px;
    margin-bottom: 20px;

    .top-title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #282828;
      margin-bottom: 20px;
    }

    .top-tag {
      display: flex;
      position: relative;
      z-index: 20;
      // justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;

      .tag-left {
        display: flex;
        width: 100%;
        // align-items: flex-start;
        align-items: center;
        margin-bottom: 25px;

        .left {
          flex: 2;
          display: flex;
          align-items: center;

          .basicSituation-item {
            color: #999;
            display: flex;
            align-items: center;

            .to-subscribe-to {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 8px;
              // border: 1px solid;
              // padding: 3px 5px;
              white-space: nowrap;
              margin-left: 10px;
            }

            .jinxing {
              border: 1px solid #990263;
              color: #990263;
            }

            .jiezhi {
              border: 1px solid #707070;
              color: #707070;
            }
          }

          .tag-price {
            height: 28px;
            border: 1px solid #8c6314;
            padding: 0 13px;
            border-radius: 4px;
            line-height: 28px;
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #8c6314;
            margin-right: 20px;
          }

          .jinxing {
            border: 1px solid #990263;
            color: #990263;
          }

          .jiezhi {
            border: 1px solid #707070;
            color: #707070;
          }

          .tag {
            display: flex;
            flex-wrap: wrap;
            max-width: 520px;

            .tag-item {
              cursor: pointer;
              height: 28px;
              padding: 0 12px;
              background-color: #fcfafd;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: #5e068c;
              display: flex;
              align-items: center;
              margin-right: 10px;
              margin-bottom: 10px;

              .text {
                margin-right: 10px;
              }

              .add {
                display: none;
              }

              .tianjia {
                display: none;
              }
            }
          }

          .create-time {
            margin-left: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 27px;
            //font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
        }

        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .share-item {
            height: 27px;
            cursor: pointer;
            position: relative;
            margin-left: 18px;
            display: flex;
            align-items: center;

            .share-image {
              margin-right: 6px;

              img {
                width: 14.46px;
                height: 12px;
              }
            }

            .share-text {
              //font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666;
            }

            .wxer-code {
              transform-origin: 0 0;
              display: none;
              position: absolute;
              border: 5px solid #000;
              top: 28px;
              left: 24px;

              &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid #000;
                position: absolute;
                top: -10px;
                left: 29px;
              }

              img {
                width: 72px;
                height: 72px;
              }
            }
          }

          .share-item:hover {
            .wxer-code {
              display: block;
            }
          }
        }
      }

      .tag-right {
        padding: 0 32px 0 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        height: 70px;
        background: linear-gradient(90deg, #f6f6f6 100%, #ffffff 100%);

        .left {

          display: flex;
          align-items: center;
          width: fit-content;

          .item {
            &:nth-child(1) {
              margin-right: 36px;
            }

            &:nth-child(2) {
              margin-right: 36px;
            }

            min-width: fit-content;
            display: flex;
            align-items: center;
            font-size: 14px;
          }
        }

        .right {
          flex: 1;
          text-align: right;
        }
      }
    }
  }

  .content-middle {
    // padding: 40px;
    background-color: #fff;
    margin-bottom: 20px;

    .middle-title {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-bottom: 31px;
    }

    .basicSituation {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 48px;

      .basicSituation-item {
        height: 60px;
        box-sizing: border-box;
        width: 50%;
        display: flex;
        align-items: center;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        padding: 20px 0px;

        .item-title {
          width: 64px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-right: 38px;
        }

        .item-details {
          max-width: 289px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          margin-right: 23px;

          span {
            color: #990263;
            font-weight: bold;
          }
        }

        .details-color {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #5e068c;
        }

        .price-margin {
          margin-right: 0px;
        }

        .qiaqia {
          cursor: pointer;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 21.36px;
          border: 1px solid #990263;
          border-radius: 3px;
          padding: 0px 6px;
          display: flex;

          .text {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 21px;
            color: #990263;
            margin-right: 4px;
          }

          .add {
            color: #990263;
          }
        }

        .to-subscribe-to {
          cursor: pointer;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          height: 21.36px;
          border: 1px solid #990263;
          border-radius: 3px;
          padding: 0px 6px;
          display: flex;

          .text {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 21px;
            color: #990263;
            margin-right: 4px;
          }

          .add {
            color: #990263;
          }
        }

        .alerdSubscribe {
          border: 0.01rem solid #707070 !important;

          .text {
            color: #707070;
          }

          .add {
            color: #707070;
          }
        }
      }
    }

    .project-paper {
      .paper-title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-bottom: 30px;
      }

      .paper-content {
        border: 1px solid #f0f0f0;
        padding: 23px 16px 40px 16px !important;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        margin-bottom: 20px;
        height: 400px;
        overflow-y: scroll;
        border-bottom: 1px solid rgb(247, 245, 245) !important;
      }

      .hiddencontent {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .paper-link {
        display: flex;
        align-items: center;

        .original-link {
          cursor: pointer;
          height: 32px;
          background: #5e068c;
          border-radius: 4px;
          color: #fff;
          padding: 0px 14px;
          box-sizing: border-box;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-right: 14px;
        }

        .noriginal-link {
          cursor: pointer;
          height: 32px;
          background: #b9b9b9;
          border-radius: 4px;
          color: #fff;
          padding: 0px 14px;
          box-sizing: border-box;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-right: 14px;
        }
      }
    }
  }

  .content-bottom {
    background-color: #fff;
    margin-bottom: 29px;

    .bottom-header {
      padding: 40px;
      padding-bottom: 30px;
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
    }

    .bottom-content {
      table {
        cursor: pointer;
        width: 100%;
        border-spacing: 0px;
        border-collapse: collapse;
        table-layout: fixed;

        thead {
          tr {
            border: 0px;
            height: 55px;

            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 21px;
              color: #3b3b3b;
              opacity: 1;
              background-color: #f9f9f9;
              border: 0px;
            }

            td:nth-of-type(1) {
              padding-left: 40px;
              width: 91px;
            }

            td:nth-of-type(2) {
              text-align: center;
              width: 85px;
            }

            td:nth-of-type(3) {
              width: 305px;
            }

            td:nth-of-type(4) {
              width: 169px;
            }

            td:nth-of-type(5) {
              width: 139px;
            }

            td:nth-of-type(6) {
              width: 83px;
            }

            td:nth-of-type(7) {
              text-align: center;
              width: 83px;
            }

            td:nth-of-type(8) {
              text-align: right;
              width: 110px;
            }

            td:nth-of-type(9) {
              text-align: right;
              padding-right: 14px;
            }
          }
        }

        tbody {
          background-color: #fff;

          tr {
            height: 55px;

            td {
              box-sizing: border-box;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
              opacity: 1;
            }

            .td-jiezhi {
              color: #707070 !important;
            }

            td:nth-of-type(1) {
              padding-left: 36px;

              div {
                box-sizing: border-box;
                height: 28px;
                border: 1px solid #607f9d;
                opacity: 1;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #607f9d;
              }
            }

            td:nth-of-type(2) {
              text-align: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              line-height: 18px;
              color: #990263;
            }

            td:nth-of-type(3) {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 6px;
            }

            td:nth-of-type(4) {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-right: 6px;
            }

            td:nth-of-type(5) {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
              padding-right: 6px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            td:nth-of-type(6) {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
            }

            td:nth-of-type(7) {
              text-align: center;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 18px;
              color: #990263;
            }

            td:nth-of-type(8) {
              text-align: right;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
            }

            td:nth-of-type(9) {
              text-align: right;
              font-size: 15px;
              padding-right: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #282828;
            }

            .tdupto {
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 18px;
              color: #707070 !important;
            }
          }
        }
      }
    }
  }
}

.z_xian {
  font-size: 14px;
  color: #666666;
  margin: 0 10px;
  width: 1px;
  height: 10px;
  background: #8E8E8E;
}

.z_index {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    width: calc(100% - 440px);
    position: relative;
  }

  .right {
    width: 340px;
  }
}

.z_xgkt {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  border: 1px solid #F7F7F7;
  margin-bottom: 170px;

  .title {
    font-size: 18px;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 12px;
      background: #5E068C;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      margin-right: 10px;
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;

    li {
      width: 100%;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      list-style-type: none;
      margin-bottom: 20px;
      cursor: pointer;

      h1 {
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
        color: #282828;
        width: 100%;
        font-weight: bold;
      }

      h2 {
        margin: 0;
        font-size: 14px;
        color: #AAAAAA;
        width: 100%;
        font-weight: normal;
      }
    }
  }
}

.z_ewmxcx {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  border: 1px solid #F7F7F7;
  display: flex;
  justify-content: space-between;
  position: relative;

  .li {
    width: 125px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
      width: 125px;
      height: 125px;
      margin-bottom: 20px;
    }

    h1 {
      margin: 0;
      margin-bottom: 10px;
      font-weight: normal;
      font-size: 14px;
      color: #282828;
    }

    h2 {
      margin: 0;
      font-size: 10px;
      color: #AAAAAA;
    }
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
    color: #B5B5B5;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
}

.to-subscribe-to {
  &.alerdSubscribe {
    .dingyue {
      display: none;
    }

    .quxiao {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 14px;
      font-size: 6px;
      color: #8E8E8E;
      font-weight: 400;
      padding: 2px 4px;
      border-radius: 5px;
      border: 1px solid #8E8E8E;
      cursor: pointer;
    }
  }

  .dingyue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 14px;
    transform: scale(.9);
    font-size: 6px;
    color: #990263;
    font-weight: 400;
    padding: 2px 4px;
    border-radius: 5px;
    border: 1px solid #990263;
    cursor: pointer;
  }

  .quxiao {
    display: none;
    transform: scale(.9);
  }
}

.z_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
}

.z_fabujigou {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
}

#editor {
  margin: auto;
  width: 80%;
  height: 580px;
  margin-top: 5rem;
}

.login-content {
  font-size: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin-top: 30px;
    margin-bottom: 60px;

    img {
      // height: 76px;
    }
  }

  .z_title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 40px;
    width: 100%;

    .a {
      padding-bottom: 12px;
      font-size: 16px;
      color: #444444;
      border-bottom: 2px solid transparent;
      margin: 0 30px;
      cursor: pointer;

      &.active {
        color: #5E068C;
        border-color: #5E068C;
      }
    }
  }

  .err-message {
    font-size: 14px;
    height: 26px;
    color: red;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .username {
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    background-color: #FFF;
    box-sizing: border-box;
    padding: 0 18px;
    width: 410px;
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: 16.76px;
      height: 22px;
      margin-right: 32px;
    }

    input {
      flex: 1;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444444;
      border: 0px;
      outline: 0px;
    }
  }

  .password {
    border-radius: 8px;
    border: 1px solid #f1f3f5;
    background-color: #FFF;
    box-sizing: border-box;
    padding: 0 18px;
    width: 408px;
    height: 56px;
    display: flex;
    align-items: center;
    margin-bottom: 42px;

    img {
      width: 18.86px;
      height: 22px;
      margin-right: 32px;
    }

    input {
      flex: 1;
      width: calc(100% - 20px);
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444444;
      border: 0px;
      outline: 0px;
    }
  }

  .errName {
    border: 1px solid red;
  }

  .errPassWord {
    border: 1px solid red;
  }

  .login-button {
    cursor: pointer;
    width: 410px;
    height: 56px;
    background: #5e068c;
    opacity: 1;
    border-radius: 4px;
    color: #fff;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.15rem;
  }

  .forget {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .forget-left {
      display: flex;
      justify-content: left;
      align-items: center;

      .radio {
        cursor: pointer;
        width: 28px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #c4c4c4;
        opacity: 1;
        border-radius: 4px;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 16.34px;
          height: 11.57px;
        }
      }

      .text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .forget-right {
      cursor: pointer;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
  }

  .to-registed {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      cursor: pointer;
      color: #990263;
    }
  }
}

.z_yzm {
  display: flex;
  width: 410px;
  margin-bottom: 0.15rem;

  .password {
    flex: 1;
    margin-right: 4px;
    margin-bottom: 0;
    width: 0;

    img {
      width: auto;
      margin-right: 26px;
    }
  }

  .anniu {
    height: 56px;
    width: 140px;
    padding: 0;
    display: flex;
    background: #FFF;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #444;
  }
}

.login-box {
  position: absolute;
  height: 120%;
  width: 100%;
  bottom: 0;
  left: 0;


  padding: 36px 0 46px 0;

}

.login-bottom {
  z-index: 11;
  position: absolute;
  background-color: #F7F7F9;
  border: 1px solid #CCCCCC33;
  width: 100%;
  height: 350px;
  bottom: 40px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-titles {
  color: #5E068C;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 16px;
  font-weight: 500;
}

.login-jianbian {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60%;
  width: 100%;
  z-index: 10;
  background: linear-gradient(180deg, rgba(255, 255, 255, .2) 10%, rgb(255, 255, 255) 46%);

}

.entry-item1 {
  margin-bottom: 7px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 0px 0px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: linear-gradient(128.34deg, rgba(248, 251, 255, 1) 0%, rgba(235, 243, 255, 1) 30.96%, rgba(213, 231, 255, 1) 100%);

  /*矩形 1263*/
  .wx-pub-top {
    display: flex;
    align-items: center;

    .wx-pub-top-left {
      flex: 1;

      .wx-pub-top-left-title {
        font-size: 18px;
        font-weight: 600;
        color: #4667E3;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .wx-pub-top-left-desc {
        font-size: 13px;
        font-weight: 400;
        color: #707070;
        line-height: 20px;
      }

      .btn-sub {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 8px;

        .btn {
          width: fit-content;
          padding: 6px 12px;
          background-color: #4667E3;
          color: white;
          border-radius: 50px;
          cursor: pointer;
        }
      }
    }

    .wx-pub-top-right {
      img {
        width: 132px;
        height: 132px;
      }
    }
  }
}

.entry-item {
  margin-bottom: 7px;
  position: relative;
  z-index: 4;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 25px 25px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  background: linear-gradient(128.34deg, rgba(240, 255, 239, 1) 0%, rgba(248, 255, 247, 1) 30.96%, rgba(249, 255, 248, 1) 100%);

  .float-bg {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/image/bg-wx.svg');
    background-repeat: no-repeat;
    background-position: 10px 10px;
  }

  /*矩形 1263*/
  .wx-pub-top {
    display: flex;
    align-items: center;

    .wx-pub-top-left {
      flex: 1;

      .wx-pub-top-left-title {
        font-size: 18px;
        font-weight: 600;
        color: #009432;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .wx-pub-top-left-desc {
        font-size: 13px;
        font-weight: 400;
        color: #707070;
        line-height: 20px;
      }
    }

    .wx-pub-top-right {
      img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .wx-pub-bottom {
    margin-top: 20px;
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #009432;
    background-color: white;
    border-radius: 100px;
    overflow: hidden;
    height: 50px;

    .input-wrapper {
      flex: 1;
      padding-left: 20px;

      input {
        box-sizing: border-box;
        outline: none;
        border: none;
        font-size: 16px;
        width: 100%;
        background-color: transparent;
      }
    }

    .entry-btn-wx {
      width: 70px;
      text-align: center;
      background-color: #009432;
      color: white;
      cursor: pointer;
      height: 50px;
      line-height: 50px;
    }
  }
}
::v-deep .el-dialog{
  background-color: transparent;
  box-shadow: none;
}
.bg-send {
      position: relative;
			/*矩形 913*/
			opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
			border-radius: 12px;
			background: linear-gradient(180deg, rgba(251, 230, 186, 1) 0%, rgba(255, 255, 255, 1) 40%) !important;
		}
		.btn-send {
			/*矩形 911*/
			display: flex;
			align-items: center;
			justify-content: center;
			width: 205px;
			height: 40px;
			font-size: 16px;
			color: #FFFFFF;
			text-align: center;
			border-radius: 21px;
			position: absolute;
			bottom: 32px;
			background: rgba(232, 153, 0, 1) !important;
		}
		.send-title{
			color: #E89900;
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 16px;
      padding-top: 90px;
      text-align: center;
		}
		.send-desc {
			width: 260px;
			line-height: 1.5em;
			text-align: center;
			font-size: 1.05em;
      padding-top: 32px;
			padding-bottom: 110px;
		}
		.send-desc span {
			font-weight: 600;
			color: #990263;
		}
		.close-tr {
			position: absolute;
			top: 24px !important;
			right: 24px !important;
			width: 14px !important;
			height: 14px !important;
		}

		.send-res1{
      position: absolute;
			top: -45px !important;
			left: calc(50% - 75px) !important;
			width: 160px !important;
			height: 140px !important;
		}

</style>
